<template>
  <div>
    <table v-if="tableHead.right" class="impairex-table mb-5">
      <thead class="table-primary text-white">
        <tr v-if="tableHead.right">
          <th
            v-for="(item, index) in tableHead.right"
            :key="index"
            scope="col"
            class="text-capitalize"
          >
            {{ item }}
          </th>
        </tr>
      </thead>

      <tbody class="table-white">
        <tr v-for="(part, index) in tableRows" :key="index" class="table-light">
          <th scope="row" class="text-capitalize">
            {{ part.display_text }}
          </th>

          <td class="pt-4">
            <select
              v-model="
                part.right.find(
                  (x) => x.field_name === `${part.api_name}_flexion`
                ).degree_id
              "
              class="w-75 form-select"
              @change="emitParts()"
            >
              <option selected disable hidden />
              <option
                v-for="option in part.flexOpts"
                :key="option.degree"
                :value="option.id"
              >
                {{ option.degree }}°
              </option>
            </select>
          </td>
          <td class="pt-4">
            <select
              v-model="
                part.right.find(
                  (x) => x.field_name === `${part.api_name}_extension`
                ).degree_id
              "
              class="w-75 form-select"
              @change="emitParts()"
            >
              <option selected disable hidden />
              <option
                v-for="option in part.extOpts"
                :key="option.degree"
                :value="option.id"
              >
                {{ option.degree }}°
              </option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>

    <table v-if="tableHead.left" class="impairex-table my-5">
      <thead class="table-primary text-white">
        <tr v-if="tableHead.left">
          <th
            v-for="(item, index) in tableHead.left"
            :key="index"
            scope="col"
            class="text-capitalize"
          >
            {{ item }}
          </th>
        </tr>
      </thead>

      <tbody class="table-white">
        <tr v-for="(part, index) in tableRows" :key="index" class="table-light">
          <th scope="row" class="text-capitalize">
            {{ part.display_text }}
          </th>

          <td class="pt-4">
            <select
              v-model="
                part.left.find(
                  (x) => x.field_name === `${part.api_name}_flexion`
                ).degree_id
              "
              class="w-75 form-select"
              @change="emitParts()"
            >
              <option selected disable hidden />
              <option
                v-for="option in part.flexOpts"
                :key="option.degree"
                :value="option.id"
              >
                {{ option.degree }}°
              </option>
            </select>
          </td>
          <td class="pt-4">
            <select
              v-model="
                part.left.find(
                  (x) => x.field_name === `${part.api_name}_extension`
                ).degree_id
              "
              class="w-75 form-select"
              @change="emitParts()"
            >
              <option selected disable hidden />
              <option
                v-for="option in part.extOpts"
                :key="option.degree"
                :value="option.id"
              >
                {{ option.degree }}°
              </option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import DegreeCheck from '@/mixins/DegreeCheckMixin'
export default {
  name: 'FingersAssessmentTable',
  mixins: [DegreeCheck],
  props: {
    tableHead: {
      type: [Array, Object],
      required: true,
    },
    tableRows: { type: [Array, Object], required: true, default: null },
    caption: { type: String, default: null },
    bodyPart: { type: String, default: null },
  },

  emits: ['update:tableRows'],

  methods: {
    emitParts() {
      let value = this.tableRows

      this.$emit('update:tableRows', value)
    },
  },
}
</script>

<style></style>
