<template>
  <div>
    <div
      v-if="pageReady"
      class="container mx-auto justify-content-center d-flex flex-column"
    >
      <PageHeader :title="`${bodyPartName}`" class="text-center mt-3" />
      <div class="container-fluid my-3">
        <div v-if="!bothDirection">
          <div class="my-3 d-flex">
            <p class="d-inline flex-grow-1">
              Does the {{ bodyPartName }} have normal range of movement?
            </p>
            <BaseRadioGroup
              v-model="isNormal.single"
              :options="defaultOptions"
            />
          </div>

          <!-- Radio option for opposite site -->
          <div class="my-3 d-flex">
            <p class="d-inline flex-grow-1">
              Enable opposite site of {{ bodyPartName }}?
            </p>
            <BaseRadioGroup v-model="allowOther" :options="defaultOptions" />
          </div>
        </div>
        <!-- Checkbox option when two direction is selected -->
        <div v-else>
          <div class="my-3 d-flex">
            <p class="d-inline flex-grow-1">
              Which of {{ bodyPartName }} has normal range of movement?
            </p>
            <BaseCheckboxGroup
              v-model="isNormal.double"
              :options="defaultOptionsForBothDirection"
            />
          </div>
        </div>

        <FingersTable
          v-model:tableRows="parts"
          :table-head="tableHeader"
          :table-rows="tableParts"
        />

        <!-- special diagnosis findings -->
        <div v-if="bothDirection">
          <div class="mt-5">
            <label class="form-label">
              Specific diagnosis findings for Right {{ selectedPart }} :
            </label>
            <textarea v-model="diagnosis.right" class="form-control" rows="2" />
          </div>

          <div class="mt-5">
            <label class="form-label">
              Specific diagnosis findings for Left {{ selectedPart }} :
            </label>
            <textarea v-model="diagnosis.left" class="form-control" rows="2" />
          </div>
        </div>

        <div v-else class="my-5">
          <label class="form-label">
            Special diagnosis findings for
            {{ bodyPartName.toLowerCase() }}:
          </label>
          <textarea
            v-model="diagnosis[`${direction}`]"
            class="form-control"
            rows="2"
          />
        </div>

        <div class="d-flex justify-content-between py-3">
          <BaseBtn
            :class="`px-3`"
            :color="`outline-danger`"
            :text-color="`danger`"
            :data-bs-toggle="`modal`"
            :data-bs-target="`#confirmationModal`"
          >
            Delete
          </BaseBtn>

          <BaseBtn :class="`px-3`" @click="onSave">Save</BaseBtn>
        </div>
      </div>

      <!-- cancel modal -->
      <div
        id="confirmationModal"
        class="modal fade confirmation-box"
        tabindex="-1"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title px-2">Confirmation Box</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div class="modal-body py-4 text-center">
              <p>
                Are you sure to cancel and remove this
                {{ bodyPartName }} selection?
              </p>
              <div
                v-if="existingTableData.direction.length === 2"
                class="mt-5 text-center"
              >
                <p>Please select which side to remove:</p>
                <div
                  v-for="side in existingTableData.direction"
                  :key="side"
                  class="form-check-inline"
                >
                  <input
                    v-model="sideToRemove"
                    type="checkbox"
                    :value="side"
                    class="form-check-input"
                  />

                  <label class="form-label ms-2">
                    {{ side === 'R' ? 'Right' : 'Left' }}
                  </label>
                </div>
              </div>
            </div>
            <div class="mb-4 d-flex justify-content-between">
              <button
                type="button"
                class="btn btn-secondary mx-auto text-white"
                data-bs-dismiss="modal"
              >
                Bring me back
              </button>
              <button
                type="button"
                class="btn btn-danger mx-auto text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="removeBodyPart"
              >
                Yes, remove it!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FingersTable from '@/components/Assessment/FingersTable.vue'
import PageHeader from '@/components/BodyPart/PageHeader.vue'
import BaseRadioGroup from '@/components/Base/BaseRadioGroup.vue'
import BaseBtn from '@/components/Base/BaseButton.vue'
import BaseCheckboxGroup from '@/components/Base/BaseCheckboxGroup.vue'

export default {
  name: 'Fingers',
  components: {
    PageHeader,
    FingersTable,
    BaseRadioGroup,
    BaseCheckboxGroup,
    BaseBtn,
  },
  props: {
    assessmentId: { type: Number, default: null },
    selectedPart: { type: String, required: true },
    existingTableData: { type: Object, default: null },
    apiPostObj: { type: [Object, Array], default: null },
  },

  emits: ['update:readyNext', 'removeBodyPart'],

  data() {
    return {
      pageTitle: 'Fingers',
      selectedParts: [],
      tableHead: [this.selectedPart + ' Joints', 'Flexion', 'Extension'],
      oppositeTableHead: [],
      direction: null,
      pageReady: true,
      allowOther: false,
      sideToRemove: [],
      postApi: {},
      parts: [],
      diagnosis: {},
      defaultOptions: [
        {
          value: true,
          label: 'Yes',
        },
        {
          value: false,
          label: 'No',
        },
      ],
      defaultOptionsForBothDirection: ['Right', 'Left'],
      isNormal: { single: true, double: ['Right', 'Left'] },
    }
  },

  computed: {
    tableHeader: function () {
      let tableHead = {}

      if (this.bothDirection || this.allowOther) {
        tableHead.left = [
          'left ' + this.selectedPart + ' joints',
          'flexion',
          'extension',
        ]
        tableHead.right = [
          'right ' + this.selectedPart + ' joints',
          'flexion',
          'extension',
        ]

        return tableHead
      } else {
        tableHead[this.direction] = [
          this.bodyPartName + ' joints',
          'flexion',
          'extension',
        ]

        return tableHead
      }
    },

    tableParts: function () {
      if (this.bothDirection || this.allowOther) {
        //temporary solution to fix to deal with untick box, doesnt trigger unload default values properly

        //flush old data and recheck checkbox input
        if (this.isNormal.double.length === 0) {
          this.parts.forEach((x) => {
            x[this.direction].map((e) => (e.degree_id = null))
          })
        }

        // render which sides need default value after user made up its mind when ticking the checkbox
        if (this.isNormal.double.length > 0) {
          this.isNormal.double.forEach((direction) => {
            this.parts.forEach((x) => {
              x[direction.toLowerCase()].forEach((e) => {
                return e.degree_id === null
                  ? (e.degree_id = e.normal_degree_id)
                  : ''
              })
            })
          })
        }

        return this.parts
      }

      if (this.isNormal.single === true) {
        this.parts.forEach((x) => {
          x[this.direction.toLowerCase()].forEach((e) => {
            e.degree_id === null ? (e.degree_id = e.normal_degree_id) : ''
          })
        })
        return this.parts
      } else {
        this.parts.forEach((x) => {
          x[this.direction.toLowerCase()].forEach((e) => {
            e.degree_id = null
          })
        })
        return this.parts
      }
    },

    bodyPartName() {
      let part = this.selectedPart

      if (this.bothDirection) {
        part = 'left & right ' + this.selectedPart
      } else return (part = `${this.direction} ${this.selectedPart}`)

      return part
    },

    bothDirection: function () {
      if (
        this.existingTableData.direction.includes('L') &&
        this.existingTableData.direction.includes('R')
      ) {
        return true
      } else {
        //set single direction's text display
        this.checkDirection()
        return false
      }
    },
  },

  mounted() {
    this.mapFingers(this.existingTableData)

    // Map existing diagnosis

    this.apiPostObj.forEach((x) =>
      x.is_selected
        ? x.direction === 'L'
          ? (this.diagnosis.left = x.specific_diagnosis)
          : x.direction === 'R'
          ? (this.diagnosis.right = x.specific_diagnosis)
          : ''
        : ''
    )

    this.pageReady = true
  },

  methods: {
    checkDirection() {
      this.direction = this.existingTableData.direction.includes('L')
        ? 'left'
        : 'right'
    },
    mapFingers(data) {
      // setup vars for finger obj mappings and final arys

      let fingerAry = []

      //common in all fingers
      let dip = {
        display_text: 'Distal Interphalangeal Joint',
        api_name: 'dip',
        right: [],
        left: [],
      }
      let pip = {
        display_text: 'Proximal Interphalangeal Joint',
        api_name: 'pip',
        right: [],
        left: [],
      }
      let mp = {
        display_text: 'Metacarpophalangeal Joint',
        api_name: 'mp',
        right: [],
        left: [],
      }

      // specfic for thumb
      // let ip = {}
      // let adduction = {}
      // let opposition = {}
      // let abduction = {}

      // degree options mapping for finger version

      data.fields.forEach((item) => {
        if (item.field_name.includes('dip')) {
          item.field_name.includes('flex')
            ? (dip['flexOpts'] = item.degrees)
            : (dip['extOpts'] = item.degrees)
        }
        if (item.field_name.includes('pip')) {
          item.field_name.includes('flex')
            ? (pip['flexOpts'] = item.degrees)
            : (pip['extOpts'] = item.degrees)
        }
        if (item.field_name.includes('mp')) {
          item.field_name.includes('flex')
            ? (mp['flexOpts'] = item.degrees)
            : (mp['extOpts'] = item.degrees)
        }
      })

      this.apiPostObj.forEach((obj) => {
        if (obj.direction === 'R') {
          dip.right = obj.fields.filter((item) =>
            item.field_name.includes('dip')
          )

          pip.right = obj.fields.filter((item) =>
            item.field_name.includes('pip')
          )

          mp.right = obj.fields.filter((item) => item.field_name.includes('mp'))
        } else {
          dip.left = obj.fields.filter((item) =>
            item.field_name.includes('dip')
          )

          pip.left = obj.fields.filter((item) =>
            item.field_name.includes('pip')
          )

          mp.left = obj.fields.filter((item) => item.field_name.includes('mp'))
        }
      })

      //finger field now has a fixed sequence
      fingerAry.push(mp, pip, dip)

      return (this.parts = fingerAry)
    },

    removeBodyPart() {
      // Emit the body part's name and direction to be removed
      let arrayOfBodyPart = []

      let obj = {
        name: this.existingTableData.name,
        directions: this.existingTableData.direction,
      }

      // When both side are selected, only removed what user selects
      if (this.existingTableData.direction.length === 1) {
        arrayOfBodyPart.push(obj)
      } else {
        obj.directions = this.sideToRemove
        arrayOfBodyPart.push(obj)
      }

      this.$emit('removeBodyPart', arrayOfBodyPart)
    },

    isDegree(value) {
      let number = parseFloat(value)
      if (!isNaN(number) && number != null) return true
      return false
    },

    async onSave() {
      let PAYLOAD = {}

      PAYLOAD.id = this.assessmentId

      let left = []
      let right = []
      this.parts.forEach((part) => {
        if (part.left.length > 0) {
          part.left.forEach((x) =>
            x.degree_id === null ? (x.degree_id = x.default) : x.degree_id
          )
          left = left.concat(part.left)
        }

        if (part.right.length > 0) {
          part.right.forEach((x) =>
            x.degree_id === null ? (x.degree_id = x.default) : x.degree_id
          )
          right = right.concat(part.right)
        }
      })

      this.apiPostObj.forEach((x) => {
        if (x.direction === 'L' && left.length > 0) {
          x.fields = left

          //map specific diagnosis
          x.specific_diagnosis = this.diagnosis.left
        }

        if (x.direction === 'R' && right.length > 0) {
          x.fields = right

          //map specific diagnosis
          x.specific_diagnosis = this.diagnosis.right
        }
      })

      PAYLOAD.payload = this.apiPostObj

      await this.$store
        .dispatch(`putNewBodyPartToAssessment`, PAYLOAD)
        .then(() => {
          let buttonEnable = true
          this.$emit('update:readyNext', buttonEnable)

          // Trigger toast text
          this.$root.toasts.push({
            status: 'success',
            content: `Successfully saved assessed ${this.bodyPartName}'s data.`,
          })
        })
        .catch((error) => {
          console.log(error)
          // Trigger toast text
          this.$root.toasts.push({
            status: 'danger',
            content: 'Something went wrong, please inform the development team. '`ERROR: ${error}`,
          })
        })
    },
  },
}
</script>

<style lang="scss">
h1 {
  font-weight: 500;
  font-size: 32px;
  color: #32c3d6;
}
</style>
